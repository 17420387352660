const TeamData = [
  {
    id: 1,
    imgUrl: 'Elizabeth_Kim_DDS.png',
    name: 'Elizabeth Kim',
    position: 'DDS',
    isDentist: true,
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 4,
        url: '.#',
        icon: 'fab fa-skype',
      },
    ],
    description_eng: [],
    description_esp: [],
  },
  {
    id: 2,
    imgUrl: 'Dinh_Nguyen_DDS.png',
    name: 'Dinh Nguyen',
    position: 'DDS, FICOI, FAGD',
    isDentist: true,
    link: '/DentistDetails/2',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 4,
        url: '.#',
        icon: 'fab fa-skype',
      },
    ],
    description_eng: [
      'Dr. Nguyen is known by his patients for his kindness, his empathy, his caring nature, and his professionalism. He is commited to bringing his patients quality and affordable dentistry. His specialty is in cosmetic, reconstructive, and implant dentistry.',
      'Dr. Nguyen received his Doctorate of Dental Surgery from the New York University College of Dentistry after earning a Chemical Engineering degree from the University of Houston. Dr. Nguyen is also a Fellow of the Academy of General Dentistry, a prestigious award for excellence in the dental profession and a commitment to providing exceptional patient care. In Implant dentistry, Dr. Nguyen is a Fellow of the International Congress of Oral Implantology (ICOI), one of the highest award for excellence in implantology.',
      'Dr. Nguyen is passionate about saving teeth and helping patients restore their lost teeth for a better overall well-being.',
    ],
    description_esp: [
      'El Dr. Nguyen es conocido por sus pacientes por su amabilidad, su empatía, su naturaleza solidaria y su profesionalismo. Está comprometido a brindar a sus pacientes una odontología asequible y de calidad. Su especialidad es la odontología cosmética, reconstructiva e implantológica.',
      'El Dr. Nguyen recibió su Doctorado en Cirugía Dental de la Facultad de Odontología de la Universidad de Nueva York después de obtener un título en Ingeniería Química de la Universidad de Houston. El Dr. Nguyen también es miembro de la Academia de Odontología General, un prestigioso premio a la excelencia en la profesión dental y el compromiso de brindar una atención excepcional al paciente. En Implantología, el Dr. Nguyen es miembro del Congreso Internacional de Implantología Oral (ICOI), uno de los más altos premios a la excelencia en implantología.',
      'Al Dr. Nguyen le apasiona salvar los dientes y ayudar a los pacientes a restaurar los dientes perdidos para un mejor bienestar general.',
    ],
  },
  {
    id: 9,
    imgUrl: 'Sarah_Stein_DDS.png',
    name: 'Sarah Stein',
    position: 'DDS',
    isDentist: true,
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 4,
        url: '.#',
        icon: 'fab fa-skype',
      },
    ],
    description_eng: [],
    description_esp: [],
  },
  {
    id: 3,
    imgUrl: 'Betty_Lee_Man.png',
    name: 'Betty Lee',
    position: 'Treatment Coordinator',
    position_esp: 'Coordinadora de tratamiento',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
  {
    id: 5,
    imgUrl: 'kathy_tran_manager.png',
    name: 'Kathy Tran',
    position: 'Manager',
    position_esp: ' La Directora',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
  {
    id: 4,
    imgUrl: 'Tammy_Fisher_Rec.png',
    name: 'Tammy Fisher',
    position: 'Receptionist',
    position_esp: ' La Recepcionista',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
  {
    id: 6,
    imgUrl: 'Tammete_RDA.png',
    name: 'Tammete',
    position: 'RDA',
    position_esp: 'RDA',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
  {
    id: 7,
    imgUrl: 'Clarissa_RDA.png',
    name: 'Clarissa',
    position: 'RDA',
    position_esp: 'RDA',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
  {
    id: 8,
    imgUrl: 'Christina_RDA.png',
    name: 'Christina',
    position: 'RDA',
    position_esp: 'RDA',
    link: '',
    socialIcon: [
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'fab fa-facebook-f',
      },
      {
        id: 2,
        url: '.#',
        icon: 'fab fa-twitter',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-linkedin-in',
      },
      {
        id: 3,
        url: '.#',
        icon: 'fab fa-youtube',
      },
    ],
  },
];

export default TeamData;
